<template>
  <a class="card" :class="{ disabled }">
    <div class="card__top">
      <p class="card__title">
        <slot name="title"></slot>
      </p>
      <div class="card__img">
        <img :src="imgSrc" alt="" />
      </div>
    </div>
    <div class="card__bottom">
      <div class="card__text">
        <p class="p-6">
          <slot name="text"></slot>
        </p>
      </div>
    </div>
  </a>
</template>

<script>
export default {
  props: {
    imgSrc: {
      type: String,
      required: true,
      default: require("@/assets/img/card-placeholder.svg"),
    },
    disabled: {
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.card {
  $self: &;
  display: block;
  padding: $space-32;
  border-radius: $radius-md;
  transition: $trans-anim;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(20, 112, 123, 0.4);
    opacity: 0.9;
    cursor: pointer;

    @include mobile {
      transform: translateY(0);
      box-shadow: none;
      opacity: 1;
      cursor: initial;
    }
  }

  @include tablet {
    padding: $space-24;
  }

  @include mobile {
    padding: $space-16;
  }

  &--middle {
    padding: $space-24;

    @include mobile {
      padding: $space-16;
    }

    #{ $self }__title {
      font-size: 24px;

      @include mobile {
        font-size: 16px;
      }
    }

    #{ $self }__img {
      width: 80px;
      height: 80px;

      @include mobile {
        width: 56px;
        height: 56px;
      }
    }
  }

  &__top {
    margin-bottom: $space-16;
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: $white;
    font-size: 32px;
    font-weight: 700;
    line-height: 1.25;

    @include tablet {
      font-size: 24px;
    }

    @include mobile {
      font-size: 16px;
    }
  }

  &__text {
    p {
      color: $white;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;

      @include tablet {
        font-size: 16px;
      }

      @include mobile {
        font-size: 14px;
      }
    }
  }

  &__img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;

    @include tablet {
      width: 80px;
      height: 80px;
    }

    @include mobile {
      width: 56px;
      height: 56px;
    }

    img {
      max-width: 60%;
      width: 72px;
    }
  }

  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
  }

  &--1 {
    background: radial-gradient(
      94.17% 92.19% at 100% 0%,
      #4cbc77 0%,
      #228d6e 100%
    );

    #{ $self }__img {
      background: rgba($green-200, 0.4);
    }
  }

  &--2 {
    background: radial-gradient(
      93.21% 106.43% at 100% 0%,
      #2fa985 0%,
      #197d89 100%
    );

    #{ $self }__img {
      background: rgba($teal-200, 0.4);
    }
  }

  &--3 {
    background: radial-gradient(
      151.95% 500.1% at 100% 0%,
      #0e98a0 0%,
      #14707b 100%
    );

    #{ $self }__img {
      background: rgba($teal-200, 0.4);
    }
  }

  &--4 {
    background: radial-gradient(
      93.95% 152.27% at 100% 0%,
      #1dc0a3 0%,
      #0e946d 100%
    );

    #{ $self }__img {
      background: rgba($teal-100, 0.4);
    }
  }
}
</style>
