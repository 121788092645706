<template>
  <div>
    <el-row
      class="calc"
      type="flex"
      justify="space-between"
      v-animate-onscroll="'animated backIn delay-05'"
    >
      <el-col :span="12" :xs="24" class="calc__right">
        <el-row class="calc__row">
          <el-col :span="24">
            <v-slider
              title="Сумма задолженности"
              :min="100000"
              :max="10000000"
              labelMin="₽"
              labelMax="₽"
              currency
              @setValue="onDebtInput"
            />
          </el-col>
        </el-row>
        <el-row class="calc__row">
          <el-col :span="24">
            <v-slider
              title="Период отсрочки"
              :min="1"
              :max="120"
              labelMin="дня"
              labelMax="дней"
              @setValue="onTimeInput"
            />
          </el-col>
        </el-row>
        <el-row class="calc__row calc__check" :gutter="24">
          <el-col class="calc__col" :span="12">
            <p class="calc__title p-4 reg">Дебиторы</p>
            <el-radio-group v-model="debitorsSelector">
              <el-radio label="1">Дебиторы — федеральные ритейлеры</el-radio>
              <el-radio label="2">Прочие дебиторы</el-radio>
            </el-radio-group>
          </el-col>
          <el-col class="calc__col" :span="12">
            <p class="calc__title p-4 reg">Договоры факторинга</p>
            <el-radio-group v-model="factorsSelector">
              <el-radio label="1"
                >Прямой договор регрессного факторинга</el-radio
              >
              <el-radio label="2">Прочие договоры факторинга</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
      </el-col>
      <el-col :span="10" :xs="24" class="calc__left">
        <div class="calc__box">
          <p class="p-3">Размер финансирования</p>
          <p class="p-2 bold">{{ cash | numСat }} ₽</p>
        </div>
        <div class="calc__box">
          <p class="p-3">Стоимость финансирования</p>
          <p class="p-2 bold">{{ paid | numСat }} ₽</p>
        </div>
        <div class="calc__btn">
          <el-button type="primary" @click="$modal.show('contact')"
            >Подать заявку</el-button
          >
        </div>
        <p class="p-6 calc__text">
          Калькулятор предоставляет примерные данные, не&nbsp;учитывающие
          некоторые особенности. Свяжитесь с&nbsp;нами, чтобы получить
          персональные условия
        </p>
      </el-col>
    </el-row>
    <v-dialog name="contact">
      <h2 class="b24">Отправить запрос</h2>
      <p class="b24">
        Мы свяжемся с вами, и найдем лучшие ставки и условия среди факторов,
        подключенных к EdiFin
      </p>
      <v-form :action="'https://ediweb.com/subscribe/9968c31f'" />
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "v-calc",
  components: {
    "v-slider": () => import("@/components/Slider"),
    "v-dialog": () => import("@/components/Dialog"),
    "v-form": () => import("@/components/Form"),
  },
  data() {
    return {
      debitorsSelector: "1",
      factorsSelector: "1",
      DEBT: 100000,
      TIME: 1,
    };
  },
  methods: {
    onDebtInput(value) {
      this.DEBT = Math.max(0, parseInt(value, 10));
    },
    onTimeInput(value) {
      this.TIME = Math.max(0, parseInt(value, 10));
    },
  },
  computed: {
    debitors() {
      return parseInt(this.debitorsSelector, 10);
    },
    factors() {
      return parseInt(this.factorsSelector, 10);
    },
    paid() {
      const d = this.debitors < 2 ? 1 : 1.125;
      const f = this.factors < 2 ? 1 : 1.2;
      const result = this.DEBT * 0.000395 * this.TIME * d * f;
      return parseInt(Math.ceil(result), 10);
    },
    cash() {
      const percent = this.debitors < 2 && this.TIME <= 40 ? 0.95 : 0.9;
      return parseInt(Math.ceil(this.DEBT * percent), 10);
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.calc {
  padding: $space-56;
  background-color: $white;
  border-radius: $radius-md;
  $self: &;

  @include mobile {
    padding: $space-32 $space-24;
  }

  @include mobile {
    flex-wrap: wrap;
    flex-direction: column;
  }

  &__right {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: -9%;
      height: 100%;
      width: 1px;
      background-color: $gray-50;

      @include mobile {
        height: 1px;
        width: 100%;
        top: auto;
        bottom: 0;
        left: 0;
        right: auto;
      }
    }

    @include mobile {
      margin-bottom: $space-32;
    }
  }

  &__row {
    margin-bottom: $space-24;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__title {
    margin-bottom: $space-16;
  }

  &__text {
    color: $gray-500;
  }

  &__box {
    margin-bottom: $space-32;

    .p-2 {
      margin-top: $space-8;
    }
  }

  &__btn {
    margin-bottom: $space-24;
  }

  &__check {
    @include tablet {
      display: flex;
      flex-direction: column;

      #{ $self }__col {
        width: 100%;
        margin-bottom: $space-24;

        .el-radio {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
