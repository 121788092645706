<template>
  <div class="range-slider">
    <p class="range-slider__title p-4 reg">{{ title }}</p>
    <div class="range-slider__controls">
      <input
        class="el-input__inner"
        v-if="edit"
        :value="val"
        @change="updateValue"
        @blur="editEnd"
        @keyup.esc="editEnd"
        @keyup.enter="updateValue"
        @keypress="isNumber"
        ref="editableInput"
      />
      <el-input v-else :value="val" @focus="focusOnInput" />
      <el-slider
        v-model="value"
        :show-tooltip="false"
        :min="min"
        :max="max"
        @input="emitToParent"
      />
    </div>
    <div class="range-slider__values">
      <span>от {{ min | numСat }} {{ labelMin }}</span>
      <span>до {{ max | numСat }} {{ labelMax }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "v-slider",
  props: {
    title: {
      required: true,
      type: String,
    },
    labelMin: {
      required: true,
      type: String,
    },
    labelMax: {
      required: true,
      type: String,
    },
    min: {
      required: true,
      type: Number,
    },
    max: {
      required: true,
      type: Number,
    },
    currency: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      edit: false,
      value: this.min,
    };
  },
  methods: {
    editEnd() {
      this.edit = false;
    },
    focusOnInput() {
      this.edit = true;
      this.$nextTick(() => {
        this.$refs.editableInput.select();
      });
    },
    updateValue($event) {
      this.value = parseInt($event.target.value);
      this.editEnd();
    },
    emitToParent() {
      this.$emit("setValue", this.value);
    },
    getCase(number, one, two, five) {
      let n = Math.abs(number);
      n %= 100;
      if (n >= 5 && n <= 20) {
        return `${number} ${five}`;
      }
      n %= 10;
      if (n === 1) {
        return `${number} ${one}`;
      }
      if (n >= 2 && n <= 4) {
        return `${number} ${two}`;
      }
      return `${number} ${five}`;
    },
    isNumber($event) {
      let char = String.fromCharCode($event.keyCode);

      if (/^\d+$/.test(char)) return true;
      else $event.preventDefault();
    },
  },
  computed: {
    val() {
      let numFormat = new Intl.NumberFormat("ru-RU").format(this.value);
      return this.currency
        ? `${numFormat} ${this.labelMin}`
        : this.getCase(this.value, "день", "дня", "дней");
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.range-slider {
  &__title {
    margin-bottom: $space-4;
  }

  &__controls {
    position: relative;
  }

  &__values {
    margin-top: $space-8;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: $gray-500;
  }

  .el-input__inner {
    height: 56px;
    font-weight: 700;
  }

  .el-slider {
    position: absolute;
    width: 100%;
    bottom: 0;

    &__runway {
      margin: 0;
      height: 4px;
      background-color: $gray-100;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &__bar {
      height: 4px;
      background-color: $green-500;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &__button {
      width: 12px;
      height: 12px;
      border: 4px solid $green-500;
    }
  }
}
</style>
