<template>
  <div class="card-review">
    <div class="card-review__rating">
      <el-rate v-model="stars" :disabled="true"></el-rate>
    </div>
    <p class="card-review__text p-4">
      <slot name="text"></slot>
    </p>
    <p class="card-review__author p-5">
      <slot name="author"></slot>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stars: 5,
    };
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";

.card-review {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: $space-32 $space-32 $space-56;
  box-shadow: 0px 2px 8px rgba(20, 112, 123, 0.12);
  border-radius: 8px;
  background-color: $white;

  @include mobile {
    padding: $space-24;
  }

  &__rating {
    .el-rate {
      height: 32px;

      &__icon {
        margin-right: -2px;
        font-size: 36px;
        color: rgb(255, 178, 63) !important;
      }

      .el-icon-star-on:before {
        content: "\2605";
      }
    }
  }

  &__text {
    margin: 24px 0 16px;

    @include mobile {
      font-size: 16px;
    }
  }

  &__author {
    margin: auto 0 0;
    color: $gray-500;
    font-weight: 400;

    span {
      font-weight: 700;
    }
  }
}
</style>
