<template>
  <div class="container">
    <br />

    <el-row>
      <v-graph />
    </el-row>
    <br />
    <br />

    <el-row :gutter="24">
      <el-col :span="8">
        <Slider
          title="Сумма задолженности"
          :min="100000"
          :max="10000000"
          labelMin="₽"
          labelMax="₽"
          currency
        />
      </el-col>
      <el-col :span="8">
        <Slider
          title="Период отсрочки"
          :min="1"
          :max="120"
          labelMin="дня"
          labelMax="дней"
        />
      </el-col>
    </el-row>
    <br />
    <br />
    <div class="line"></div>
    <div style="background-color: #ccc">
      <Calc />
    </div>
    <br />
    <br />
    <div class="line"></div>

    <el-row>
      <h1>H1 Headline</h1>
      <p class="p-1">
        Лид — это первый абзац статьи, информативный отрывок позволяющий
        захватить внимание читателя на данном материале. Необязательный элемент.
      </p>
      <p class="p-2">
        Создавайте, отправляйте, принимайте, подписывайте и храните юридически
        значимые документы без бумаги.
      </p>
      <p class="p-3">
        Текст состоит из некоторого количества предложений. Одно предложение,
        даже очень распространённое, сложное, текстом назвать нельзя, поскольку
        текст можно расчленить на самостоятельные предложения, а части
        предложения сочетаются по законам синтаксиса сложного предложения, но не
        текста.
      </p>
      <p class="p-4">
        Текст состоит из некоторого количества предложений. Одно предложение,
        даже очень распространённое, сложное, текстом назвать нельзя, поскольку
        текст можно расчленить на самостоятельные предложения, а части
        предложения сочетаются по законам синтаксиса сложного предложения, но не
        текста.
      </p>
      <p class="p-5">
        Текст состоит из некоторого количества предложений. Одно предложение,
        даже очень распространённое, сложное, текстом назвать нельзя, поскольку
        текст можно расчленить на самостоятельные предложения, а части
        предложения сочетаются по законам синтаксиса сложного предложения, но не
        текста.
      </p>
      <p class="p-6">
        Текст состоит из некоторого количества предложений. Одно предложение,
        даже очень распространённое, сложное, текстом назвать нельзя, поскольку
        текст можно расчленить на самостоятельные предложения, а части
        предложения сочетаются по законам синтаксиса сложного предложения, но не
        текста.
      </p>
      <h2>H2 Headline</h2>
      <h3>H3 Headline</h3>
      <h4>H4 Headline</h4>
      <h5>H5 Headline</h5>
      <h6>H6 Headline</h6>
    </el-row>

    <div class="line"></div>

    <el-row>
      <p class="h2">Form</p>
      <Form />
    </el-row>
    <br />
    <br />
    <el-row
      style="
        background: linear-gradient(270deg, #2fa985 0%, #1a8a95 100%);
        padding: 20px;
      "
    >
      <Form class="dark-bg" />
    </el-row>
    <br />
    <br />
    <el-row>
      <el-input placeholder="Disabled input" :disabled="true"> </el-input>
      <br />
      <br />
      <el-input
        type="textarea"
        :rows="3"
        placeholder="Введите текст"
        v-model="textarea"
      >
      </el-input>
    </el-row>

    <div class="line"></div>

    <el-row>
      <p class="h2">Links</p>
      <router-link :to="{ name: 'Demo' }">Standart link</router-link>
      <br />

      <p style="color: violet">
        Integrated sit amet, consectetur adipisicing elit. Consequatur
        excepturi, expedita labore minima modi, assumenda laborum sint eaque
        libero nobis nam unde
        <router-link :to="{ name: 'Demo' }" class="int-link"
          >Integrated link</router-link
        >, magnam facere hic voluptas omnis commodi nihil!
      </p>

      <router-link :to="{ name: 'Demo' }" class="nav-link"
        >Navigation link</router-link
      >
      <br />

      <router-link :to="{ name: 'Demo' }" class="menu-link"
        >Menu link</router-link
      >
      <br />
    </el-row>

    <div class="line"></div>

    <p class="h2">Buttons</p>
    <el-row>
      <p>default size</p>
      <el-button type="primary">Primary button</el-button>
      <el-button type="primary" disabled>Primary button disabled</el-button>
      <br />
      <br />
      <el-button type="success">Success button</el-button>
      <el-button type="success" disabled>Success button disabled</el-button>
      <br />
      <br />
      <el-button plain type="primary">Outlined button</el-button>
      <el-button plain type="primary" disabled
        >Outlined button disabled</el-button
      >
      <br />
      <br />
      <el-button plain type="success">Outlined button</el-button>
      <el-button plain type="success" disabled>Disabled</el-button>
    </el-row>
    <br />
    <el-row>
      <p>mini size</p>
      <el-button type="primary" size="small">Primary button</el-button>
      <el-button type="primary" size="small" disabled>Disabled</el-button>
      <br />
      <br />
      <el-button type="success" size="small">Success button</el-button>
      <el-button type="success" size="small" disabled>Disabled</el-button>
      <br />
      <br />
      <el-button plain type="primary" size="small">Outlined button</el-button>
      <el-button plain type="primary" size="small" disabled>Disabled</el-button>
      <br />
      <br />
      <el-button plain type="success" size="small">Outlined button</el-button>
      <el-button plain type="success" size="small" disabled>Disabled</el-button>
    </el-row>
    <el-row>
      <p>big size</p>
      <el-button type="primary" size="medium">Primary button</el-button>
      <el-button type="primary" size="medium" disabled>Disabled</el-button>
      <br />
      <br />
      <el-button type="success" size="medium">Success button</el-button>
      <el-button type="success" size="medium" disabled>Disabled</el-button>
      <br />
      <br />
      <el-button plain type="primary" size="medium">Outlined button</el-button>
      <el-button plain type="primary" size="medium" disabled
        >Disabled</el-button
      >
      <br />
      <br />
      <el-button plain type="success" size="medium">Outlined button</el-button>
      <el-button plain type="success" size="medium" disabled
        >Disabled</el-button
      >
    </el-row>
    <br />
    <div class="line"></div>
    <p class="h2">Card</p>
    <el-row :gutter="20">
      <el-col :span="6">
        <Card
          class="card--1"
          :disabled="true"
          routerLinkName="Demo"
          :imgSrc="require('@/assets/img/cards/01.svg')"
        >
          <template v-slot:title> Факторинг <br />без регресса </template>
          <template v-slot:text>
            Покупка до 100% <br />
            До 120 дней <br />
            От 5-10 дебиторов <br />
            От 17% годовых
          </template>
        </Card>
      </el-col>
      <el-col :span="6">
        <Card
          class="card--2"
          routerLinkName="Home"
          :imgSrc="require('@/assets/img/cards/02.svg')"
        >
          <template v-slot:title>
            Закрытый <br />
            факторинг
          </template>
          <template v-slot:text>
            Финансирование до 90% <br />
            До 120 дней <br />
            Номинальный счёт <br />
            От 16% годовых
          </template>
        </Card>
      </el-col>
      <el-col :span="6">
        <Card
          class="card--3"
          routerLinkName="About"
          :imgSrc="require('@/assets/img/cards/03.svg')"
        >
          <template v-slot:title>
            Факторинг <br />
            c&nbsp;регрессом
          </template>
          <template v-slot:text>
            Финансирование до 100% <br />
            До 180 дней <br />
            Любые дебиторы <br />
            От 15% годовых
          </template>
        </Card>
      </el-col>
      <el-col :span="6">
        <Card
          class="card--4"
          routerLinkName="Support"
          :imgSrc="require('@/assets/img/cards/04.svg')"
        >
          <template v-slot:title>
            Реверсивный <br />
            факторинг
          </template>
          <template v-slot:text>
            Финансирование до 100% <br />
            До 120 дней <br />
            Любые поставщики <br />
            От 16% годовых
          </template>
        </Card>
      </el-col>
    </el-row>
    <br />
    <div class="line"></div>
    <p class="h2">Card Review</p>
    <el-row :gutter="20">
      <el-col :span="6">
        <CardReview>
          <template v-slot:text>
            &laquo;В&nbsp;результате сотрудничества наша компания ушла
            от&nbsp;проблемы кассового разрыва, обострившейся во&nbsp;время
            пандемии. Мы&nbsp;получили возможность направлять деньги
            на&nbsp;свои производственные и&nbsp;операционные
            потребности&raquo;.</template
          >
          <template v-slot:author>
            <span>Мамедов&nbsp;Р.&nbsp;Ф.</span>, директор ООО Инлайт Групп
          </template>
        </CardReview>
      </el-col>
      <el-col :span="6">
        <CardReview>
          <template v-slot:text>
            &laquo;Ранее мы&nbsp;испытывали дискомфорт с&nbsp;длительным
            процессом пополнением оборотных средств и&nbsp;медленным
            товарооборотом. Теперь кассовые разрывы ликвидированы. Оборотный
            капитал пополнен. Все факторинговые сделки находятся под
            контролем&raquo;.</template
          >
          <template v-slot:author>
            <span>Абрамов&nbsp;Т.&nbsp;Д.</span>, генеральный директор ООО
            Континент
          </template>
        </CardReview>
      </el-col>
      <el-col :span="6">
        <CardReview>
          <template v-slot:text>
            &laquo;Мы&nbsp;ускорили процесс получения денежных средств
            и&nbsp;сократили трудозатраты на&nbsp;ведение документооборота.
            Стали направлять деньги на&nbsp;закрытие своих потребностей, получая
            их&nbsp;максимально быстро после поставки&raquo;.</template
          >
          <template v-slot:author>
            <span>Пилипко&nbsp;О.&nbsp;Н.</span>, генеральный директор ООО
            Оптимафуд
          </template>
        </CardReview>
      </el-col>
      <el-col :span="6">
        <CardReview>
          <template v-slot:text>
            &laquo;Быстрое пополнение оборотных средств является насущной
            необходимостью нашего предприятия. Вместо нескольких недель
            мы&nbsp;стали получать оплату буквально в&nbsp;день поставки. При
            этом, у&nbsp;нас появился контроль за&nbsp;состоянием
            заявок&raquo;.</template
          >
          <template v-slot:author>
            <span>Сабурова&nbsp;Л.&nbsp;В.</span>, генеральный директор ООО
            Лидинг
          </template>
        </CardReview>
      </el-col>
    </el-row>
    <br />
    <div class="line"></div>
    <el-row>
      <el-col :span="24">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="Option A"></el-checkbox>
          <el-checkbox label="Option B"></el-checkbox>
          <el-checkbox label="Option C"></el-checkbox>
          <el-checkbox label="disabled" disabled></el-checkbox>
          <el-checkbox label="selected and disabled" disabled></el-checkbox>
        </el-checkbox-group>
      </el-col>
    </el-row>
    <br />
    <div class="line"></div>
    <el-row>
      <el-col :span="24">
        <el-radio-group v-model="radio">
          <el-radio label="1">Option A</el-radio>
          <el-radio label="2">Option B</el-radio>
          <el-radio disabled label="disabled">Option C</el-radio>
        </el-radio-group>
        <br />
        <br />
        <el-radio-group v-model="radioDisabled">
          <el-radio disabled label="disabled">Option C</el-radio>
          <el-radio disabled label="selected and disabled">Option C</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Form from "@/components/Form";
import Card from "@/components/Card";
import CardReview from "@/components/CardReview";
import Slider from "@/components/Slider";
import Calc from "@/components/Calc";

export default {
  components: {
    Form,
    Card,
    CardReview,
    Slider,
    Calc,
    "v-graph": () => import("@/components/Graph"),
  },
  data() {
    return {
      textarea: "",
      card: {
        title: "Факторинг <br /> без регресса",
      },
      checkList: ["selected and disabled", "Option A"],
      radio: "1",
      radioDisabled: "selected and disabled",
    };
  },
  metaInfo: {
    title: "Демонстрация",
    meta: [
      {
        name: "robots",
        content: "none",
      },
    ],
  },
};
</script>

<style>
.line {
  margin: 1em 0;
  width: 100%;
  background-color: rgb(126, 126, 126);
  height: 2px;
}
</style>
